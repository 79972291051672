<section class="page-content fadeIn">
  <h1>
    <span>Sedation</span>
  </h1>
  <p>Anxiety during a dental appointment can create unhealthy stress, exacerbate pre-existing medical conditions, and compromise the quality of the dental work. One of our most important goals is to ensure that our patients have a positive experience in the office during treatment. We routinely provide music through headphones, blankets, and special pillows for patient comfort and to aid in relaxation. In addition, the following sedation options are available and can be used, depending on the type of the procedure and patient needs:</p>
  <h2>Nitrous Oxide (Laughing Gas)</h2>
  <p>Nitrous Oxide is a gas that is mixed with oxygen and is inhaled during the procedure. Effects of Nitrous Oxide are typically very mild. Patients can drive themselves home immediately after the procedure. Nitrous Oxide does not affect a patient’s recollection of the appointment.</p>
  <h2>Oral Sedation</h2>
  <p>Oral medication such as Valium or Ativan can be taken shortly before the scheduled appointment to reduce anxiety. Oral medication provides a higher degree of sedation than Nitrous Oxide. Some patients may fall asleep during the procedure after taking the medication and not remember part of the appointment. When oral sedation is used, patients cannot drive and must have an escort to provide transportation to and from the office.</p>
  <h2>Intravenous Conscious Sedation (IV Sedation)</h2>
  <p>IV sedation provides the most profound level of sedation to reduce anxiety. The medication is delivered directly into the bloodstream through an IV line. Patients are able to breathe normally, but often times will fall asleep during the procedure. The level of sedation can accurately be controlled while the patient is monitored during the appointment. Patients receiving IV sedation cannot drive themselves to and from the office and must have an escort present.</p>
</section>
