import { Component } from '@angular/core';

@Component({
  selector: 'app-scaling-and-root-planing',
  templateUrl: './scaling-and-root-planing.component.html',
  styleUrls: ['./scaling-and-root-planing.component.scss']
})
export class ScalingAndRootPlaningComponent {

}
