<section class="page-content">
  <h1>
    <span>Contact</span>
  </h1>
  <div class="hour-banner">
    <table>
      <tr>
        <td>{{mobileQuery480.matches ? 'MON' :'Monday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'TUE' :'Tuesday'}}</td>
        <td>8:30 - 5:00</td>
        <td>(Lunch 12:00 - 2:00)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'WED' :'Wednesday'}}</td>
        <td>8:30 - 12:00</td>
        <td>(No doctors, staff only)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'THU' :'Thursday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'FRI' :'Friday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
    </table>
  </div>
  <h2>Locations</h2>
  <p>
    8350 East Kemper Rd. Unit C<br>
    Cincinnati, OH 45429<br>
    <a href="tel:+5139844867">513.984.4867</a>
  </p>
  <p>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3088.5507757070022!2d-84.33610368437583!3d39.27575813215709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884056a2bbf8bd85%3A0x3c0fe8bd6dd436bc!2s8350%20E%20Kemper%20Rd%2C%20Cincinnati%2C%20OH%2045249!5e0!3m2!1sen!2sus!4v1609278963439!5m2!1sen!2sus" width="100%" height="300px" frameborder="0" allowfullscreen="allowfullscreen" aria-hidden="false" tabindex="0" style="border: 0;">
    </iframe>
  </p>
  <p>
    7665 Monarch Ct.<br>
    West Chester Township, OH 45069<br>
    <a href="tel:+5135636611">513.563.6611</a>
  </p>
  <p>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3085.2089449722816!2d-84.37344918417739!3d39.351504679503186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88405a0a1c06b773%3A0x7cbda9bf639cefa!2s7665+Monarch+Ct%2C+West+Chester+Township%2C+OH+45069!5e0!3m2!1sen!2sus!4v1517267302493" width="100%" height="300px" frameborder="0" allowfullscreen="allowfullscreen" tabindex="1" style="border: 0;">
    </iframe>
  </p>
</section>
