<section class="page-content">
  <h1><span>Dr. Maggie Weber D.D.S.</span></h1>
  <div class="align-bottom" [ngClass]="mobileQuery960.matches ? 'column' : 'row'">
    <div class="col1">
      <img src="../../assets/imgs/dr-margaret-weber/margaret.png" alt="Doctor Margaret Weber" class="weber-pic"/>
    </div>
    <div class="col2">
      <p>
        Dr. Weber was born and raised in Park Hills, Kentucky. She attended Notre Dame Academy prior to completing her undergraduate degree at the University of Dayton. She then went on to earn a Doctor of Dental Medicine from the University of Kentucky. After fulfilling her dental degree, she moved to New Orleans to complete her periodontal residency at Louisiana State University.
      </p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-margaret-weber/weber1.jpg" alt="Doctor Weber and Family"/>
      </p>
      <p>
        Dr. Weber wants to treat all her patients with the greatest possible care in a relaxing and safe manner. She is committed to pursuing continued education in order to deliver her patients the most individualized, high-quality treatment options. She wants to ensure that her patients are happy and comfortable with their decision in care.
      </p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-margaret-weber/weber2.jpg" alt="Doctor Weber and Family"/>
      </p>
      <p>
        Dr. Weber is certified in IV sedation and is an active member of the American Dental Association, the American Academy of Periodontology, the Ohio and Cincinnati Dental Associations, and Midwest Society of Periodontics, and the American Dental Society of Anesthesiology.
      </p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-margaret-weber/weber3.jpg" alt="Doctor Weber and Family"/>
      </p>
      <p>
        Dr. Weber is married to Kyle McGrail. They share two dogs together, Brewer and Jacs. When you don’t see Dr. Weber in the office, you can likely find her either at Orangetheory fitness, baking in the kitchen, or walking her dogs!
      </p>
    </div>
  </div>
</section>
