import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ThreeDTechnologyComponent } from './three-d-technology/three-d-technology.component';
import { TreatmentOfPeriodontalDiseaseComponent } from './treatment-of-periodontal-disease/treatment-of-periodontal-disease.component';
import { SinusAugmentationComponent } from './sinus-augmentation/sinus-augmentation.component';
import { SedationComponent } from './sedation/sedation.component';
import { ScalingAndRootPlaningComponent } from './scaling-and-root-planing/scaling-and-root-planing.component';
import { RidgeAugmentationComponent } from './ridge-augmentation/ridge-augmentation.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReferringDoctorsComponent } from './referring-doctors/referring-doctors.component';
import { PeriodontalSurgeryComponent } from './periodontal-surgery/periodontal-surgery.component';
import { PeriodontalMaintenanceComponent } from './periodontal-maintenance/periodontal-maintenance.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { OralPlasticSurgeyComponent } from './oral-plastic-surgey/oral-plastic-surgey.component';
import { OralPathologyComponent } from './oral-pathology/oral-pathology.component';
import { LinksComponent } from './links/links.component';
import { LaserSurgeryComponent } from './laser-surgery/laser-surgery.component';
import { ImplantSupportedDenturesComponent } from './implant-supported-dentures/implant-supported-dentures.component';
import { ImpactedToothUncoveryComponent } from './impacted-tooth-uncovery/impacted-tooth-uncovery.component';
import { GingivalGraftingComponent } from './gingival-grafting/gingival-grafting.component';
import { FrenectomyComponent } from './frenectomy/frenectomy.component';
import { FormsComponent } from './forms/forms.component';
import { EstheticSmileDesignComponent } from './esthetic-smile-design/esthetic-smile-design.component';
import { DrVladShapiroComponent } from './dr-vlad-shapiro/dr-vlad-shapiro.component';
import { DrMargaretWeberComponent } from './dr-margaret-weber/dr-margaret-weber.component';
import { DrAndrewBakerComponent } from './dr-andrew-baker/dr-andrew-baker.component';
import { DentalImplantsComponent } from './dental-implants/dental-implants.component';
import { CrownLengtheningComponent } from './crown-lengthening/crown-lengthening.component';
import { ContactComponent } from './contact/contact.component';
import { BoneGraftingAndRegenerationComponent } from './bone-grafting-and-regeneration/bone-grafting-and-regeneration.component';
import { AtraumaticToothExtractionsComponent } from './atraumatic-tooth-extractions/atraumatic-tooth-extractions.component';

const routes: Routes = [
  { path: 'atraumatic-tooth-extractions', component: AtraumaticToothExtractionsComponent },
  { path: 'bone-grafting-and-regeneration', component: BoneGraftingAndRegenerationComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'crown-lengthening', component: CrownLengtheningComponent },
  { path: 'dental-implants', component: DentalImplantsComponent },
  { path: 'dr-andrew-baker', component: DrAndrewBakerComponent },
  { path: 'dr-margaret-weber', component: DrMargaretWeberComponent },
  { path: 'dr-vlad-shapiro', component: DrVladShapiroComponent },
  { path: 'esthetic-smile-design', component: EstheticSmileDesignComponent },
  { path: 'forms', component: FormsComponent },
  { path: 'frenectomy', component: FrenectomyComponent },
  { path: 'gingival-grafting', component: GingivalGraftingComponent },
  { path: 'impacted-tooth-uncovery', component: ImpactedToothUncoveryComponent },
  { path: 'implant-supported-dentures', component: ImplantSupportedDenturesComponent },
  { path: 'laser-surgery', component: LaserSurgeryComponent },
  { path: 'links', component: LinksComponent },
  { path: 'oral-pathology', component: OralPathologyComponent },
  { path: 'oral-plastic-surgery', component: OralPlasticSurgeyComponent },
  { path: 'patient-info', component: PatientInfoComponent },
  { path: 'periodontal-maintenance', component: PeriodontalMaintenanceComponent },
  { path: 'periodontal-surgery', component: PeriodontalSurgeryComponent },
  { path: 'referring-doctors', component: ReferringDoctorsComponent },
  { path: 'reviews', component: ReviewsComponent },
  { path: 'ridge-augmentation', component: RidgeAugmentationComponent },
  { path: 'scaling-and-root-planing', component: ScalingAndRootPlaningComponent },
  { path: 'sedation', component: SedationComponent },
  { path: 'sinus-augmentation', component: SinusAugmentationComponent},
  { path: 'three-d-technology', component: ThreeDTechnologyComponent },
  { path: 'treatment-of-periodontal-disease', component: TreatmentOfPeriodontalDiseaseComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
