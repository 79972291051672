<section class="page-content fadeIn">
  <h1>
    <span>Frenectomy</span>
  </h1>
  <p>Muscle attachment to the gingiva (gums) inside the mouth is known as a frenum. Frenums vary in size as well as location. A frenum is covered with thin mucosal tissue (like cheek tissue) and may become irritated and uncomfortable and interfere with proper oral hygiene. Additionally, due to the elastic nature of frenums, they can cause teeth to shift resulting in a space opening up, particularly between the two front teeth. In certain cases, frenums are thought to cause gingival (gum) recession. Frenectomy is a simple procedure which moves the frenum away from the teeth where it will not cause harm (Figures 1a-b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/frenectomy/FrenectomyFigure1a.jpg"
        alt="Figure 1a: Abnormally large frenum between the two front teeth">
        <figcaption>Figure 1a: Abnormally large frenum between the two front teeth</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/frenectomy/FrenectomyFigure1b.jpg"
        alt="Figure 1b: Frenum repositioned with a frenectomy"/>
        <figcaption>Figure 1b: Frenum repositioned with a frenectomy</figcaption>
      </figure>
    </div>
  </div>
</section>
