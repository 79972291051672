import { Component } from '@angular/core';

@Component({
  selector: 'app-periodontal-maintenance',
  templateUrl: './periodontal-maintenance.component.html',
  styleUrls: ['./periodontal-maintenance.component.scss']
})
export class PeriodontalMaintenanceComponent {

}
