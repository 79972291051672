import { Component } from '@angular/core';

@Component({
  selector: 'app-ridge-augmentation',
  templateUrl: './ridge-augmentation.component.html',
  styleUrls: ['./ridge-augmentation.component.scss']
})
export class RidgeAugmentationComponent {

}
