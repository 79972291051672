<section class="page-content fadeIn">
  <h1>
    <span>Dental Implants For Retention Of Removable Partial Dentures</span>
  </h1>
  <p>Dental implants can be used to retain partial dentures in areas where supporting teeth are weakened or missing (Figures 1a-d). Advantages of using implants for retention of partial dentures include improved function and esthetics, better stability, as well as improved long term prognosis of the existing teeth due to decreased stress.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image002.jpg"
        alt="Figure 1a: Implants are placed in the areas of the missing teeth">
        <figcaption>Figure 1a: Implants are placed in the areas of the missing teeth</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image004.jpg"
        alt="Figure 1b: New Removable Partial Denture is fabricated or an existing denture is adjusted"/>
        <figcaption>Figure 1b: New Removable Partial Denture is fabricated or an existing denture is adjusted</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image006.jpg"
        alt="Figure 1c: Attachments are placed inside the denture that connect to the implants">
        <figcaption>Figure 1c: Attachments are placed inside the denture that connect to the implants</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image008.jpg"
        alt="Figure 1d: The Removable Partial Denture is retained by the implants without metal clasps on the existing natural teeth"/>
        <figcaption>Figure 1d: The Removable Partial Denture is retained by the implants without metal clasps on the existing natural teeth</figcaption>
      </figure>
    </div>
  </div>
  <h2>Dental Implants For Retention Of Complete Dentures</h2>
  <p>Dental implants can be used to provide proper retention for removable complete dentures. Due to unfavorable anatomy of the lower jaw, and movement of the tongue, retention of conventional complete dentures is difficult. Two to four implants can be predictably used to provide adequate retention of the dentures (Figures 2a-d). Implant retained dentures are more comfortable, more stable, and provide better function. In addition, dental implants minimize natural bone resorption which is common in completely edentulous jaws.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image010.jpg"
        alt="Figure 2a: Two implants are placed in the areas previously occupied by natural canines">
        <figcaption>Figure 2a: Two implants are placed in the areas previously occupied by natural canines</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image012.jpg"
        alt="Figure 2b: New complete dentures are fabricated, or existing dentures adjusted"/>
        <figcaption>Figure 2b: New complete dentures are fabricated, or existing dentures adjusted</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image014.jpg"
        alt="Figure 2c: Attachments are placed inside the dentures that connect to the dental implants">
        <figcaption>Figure 2c: Attachments are placed inside the dentures that connect to the dental implants</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/implant-supported-dentures/dentures_clip_image016.jpg"
        alt="Figure 2d: The dentures snap onto the dental implants with adequate retention"/>
        <figcaption>Figure 2d: The dentures snap onto the dental implants with adequate retention</figcaption>
      </figure>
    </div>
  </div>
</section>
