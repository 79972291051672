import { ChangeDetectorRef, Component } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  mobileQuery480: MediaQueryList;
  mobileQuery720: MediaQueryList;
  mobileQuery960: MediaQueryList;
  mobileQuery1024: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) {
    this.mobileQuery480 = media.matchMedia('(max-width: 480px)');
    this.mobileQuery720 = media.matchMedia('(max-width: 720px)');
    this.mobileQuery960 = media.matchMedia('(max-width: 960px)');
    this.mobileQuery1024 = media.matchMedia('(max-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery480.addListener(this._mobileQueryListener);
    this.mobileQuery720.addListener(this._mobileQueryListener);
    this.mobileQuery960.addListener(this._mobileQueryListener);
    this.mobileQuery1024.addListener(this._mobileQueryListener);
  }

  goTo(r:string) {
    this.router.navigate([r]);
  }
}
