<section class="page-content">
  <h1><span>Dr. Andrew Baker D.D.S., M.S.</span></h1>
  <div class="align-bottom" [ngClass]="mobileQuery960.matches ? 'column' : 'row'">
    <div class="col1">
      <img src="../../assets/imgs/dr-andrew-baker/andrew.png" alt="Doctor Andrew Baker" class="andrew-pic"/>
    </div>
    <div class="col2">
      <p>
        Dr. Baker is an Ohio native, having grown up in Toledo and graduated from St. Johns Jesuit High School. He then attended Xavier University where he received a Bachelor’s Degree in Natural Sciences. Dr. Baker continued his education at The Ohio State University College of Dentistry including conducting research on dental materials. During His time at OSU he joined the United States Air Force and was accepted into their Periodontal Residency Program at Lackland Air Force Base in San Antonio, Texas. While in his residency Dr. Baker did research in the area of stem cell and bone biology at the University of Texas Health Science Center San Antonio, where he completed his Master’s Degree. Upon completing his residency in periodontics, Dr. Baker was stationed at Wright Patterson Air Force Base in Dayton where he was Chief Periodontal Training Officer, training over 20 residents in periodontics and providing services for over 5,000 active duty troops. In addition, Dr. Baker maintained positions as Chief of Preventive Dentistry and Chief of Forensic Dentistry and was responsible for compliance and operation for the base dental laser and tissue grafting programs. Dr. Baker has held positions as adjunct faculty at The Ohio State University College of Dentistry and as President of the Ohio Academy of Periodontists. He currently serves in the United States Air Force Reserve at the rank of Lt. Colonel.
      </p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-andrew-baker/andrew-family.png" alt="Doctor Andrew Baker and Family"/>
      </p>
      <p>
        Dr. Baker is married to his wife Robin (a Cincinnati native) and is the proud father of his daughter Emma and son John. In his spare time he enjoys time with his family, baking, and studying history.
      </p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-andrew-baker/BakerPhoto3-e1439423931936.jpg" alt="Doctor Andrew Baker and Family"/>
      </p>
    </div>
  </div>
</section>
