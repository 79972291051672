<section class="page-content fadeIn">
  <h1>
    <span>Esthetic Smile Design</span>
  </h1>
  <p>Oral plastic surgery procedures can be combined with veneers or crowns to enhance a person’s smile. Prior to placement of the restorations, the gum line is recontoured to provide symmetry as well as correct tooth proportions. The final step involves minor preparation of teeth and placement of veneers or crowns which improve the color and shape of teeth.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/esthetic-smile/implants_clip_image002.jpg"
        alt="Figure 1a: Before treatment">
        <figcaption>Figure 1a: Before treatment</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/esthetic-smile/implants_clip_image004.jpg"
        alt="Figure 1b: After treatment"/>
        <figcaption>Figure 1b: After treatment</figcaption>
      </figure>
    </div>
  </div>
</section>
