import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

import { HomeComponent } from './home/home.component';
import { AtraumaticToothExtractionsComponent } from './atraumatic-tooth-extractions/atraumatic-tooth-extractions.component';
import { BoneGraftingAndRegenerationComponent } from './bone-grafting-and-regeneration/bone-grafting-and-regeneration.component';
import { ContactComponent } from './contact/contact.component';
import { CrownLengtheningComponent } from './crown-lengthening/crown-lengthening.component';
import { DentalImplantsComponent } from './dental-implants/dental-implants.component';
import { DrAndrewBakerComponent } from './dr-andrew-baker/dr-andrew-baker.component';
import { DrMargaretWeberComponent } from './dr-margaret-weber/dr-margaret-weber.component';
import { DrVladShapiroComponent } from './dr-vlad-shapiro/dr-vlad-shapiro.component';
import { EstheticSmileDesignComponent } from './esthetic-smile-design/esthetic-smile-design.component';
import { FormsComponent } from './forms/forms.component';
import { FrenectomyComponent } from './frenectomy/frenectomy.component';
import { GingivalGraftingComponent } from './gingival-grafting/gingival-grafting.component';
import { ImpactedToothUncoveryComponent } from './impacted-tooth-uncovery/impacted-tooth-uncovery.component';
import { ImplantSupportedDenturesComponent } from './implant-supported-dentures/implant-supported-dentures.component';
import { LaserSurgeryComponent } from './laser-surgery/laser-surgery.component';
import { LinksComponent } from './links/links.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { OralPathologyComponent } from './oral-pathology/oral-pathology.component';
import { OralPlasticSurgeyComponent } from './oral-plastic-surgey/oral-plastic-surgey.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { PeriodontalMaintenanceComponent } from './periodontal-maintenance/periodontal-maintenance.component';
import { PeriodontalSurgeryComponent } from './periodontal-surgery/periodontal-surgery.component';
import { ReferringDoctorsComponent } from './referring-doctors/referring-doctors.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { RidgeAugmentationComponent } from './ridge-augmentation/ridge-augmentation.component';
import { ScalingAndRootPlaningComponent } from './scaling-and-root-planing/scaling-and-root-planing.component';
import { SedationComponent } from './sedation/sedation.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SinusAugmentationComponent } from './sinus-augmentation/sinus-augmentation.component';
import { ThreeDTechnologyComponent } from './three-d-technology/three-d-technology.component';
import { TreatmentOfPeriodontalDiseaseComponent } from './treatment-of-periodontal-disease/treatment-of-periodontal-disease.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AtraumaticToothExtractionsComponent,
    BoneGraftingAndRegenerationComponent,
    ContactComponent,
    CrownLengtheningComponent,
    DentalImplantsComponent,
    DrAndrewBakerComponent,
    DrMargaretWeberComponent,
    DrVladShapiroComponent,
    EstheticSmileDesignComponent,
    FormsComponent,
    FrenectomyComponent,
    GingivalGraftingComponent,
    ImpactedToothUncoveryComponent,
    ImplantSupportedDenturesComponent,
    LaserSurgeryComponent,
    LinksComponent,
    MainMenuComponent,
    OralPathologyComponent,
    OralPlasticSurgeyComponent,
    PatientInfoComponent,
    PeriodontalMaintenanceComponent,
    PeriodontalSurgeryComponent,
    ReferringDoctorsComponent,
    ReviewsComponent,
    RidgeAugmentationComponent,
    ScalingAndRootPlaningComponent,
    SedationComponent,
    SideMenuComponent,
    SinusAugmentationComponent,
    ThreeDTechnologyComponent,
    TreatmentOfPeriodontalDiseaseComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatSliderModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
  ],
  exports: [
    MatSliderModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
