import { Component } from '@angular/core';

@Component({
  selector: 'app-gingival-grafting',
  templateUrl: './gingival-grafting.component.html',
  styleUrls: ['./gingival-grafting.component.scss']
})
export class GingivalGraftingComponent {

}
