import { Component } from '@angular/core';

@Component({
  selector: 'app-esthetic-smile-design',
  templateUrl: './esthetic-smile-design.component.html',
  styleUrls: ['./esthetic-smile-design.component.scss']
})
export class EstheticSmileDesignComponent {

}
