<section class="page-content fadeIn">
  <h1>
    <span>Impacted Tooth Uncovery</span>
  </h1>
  <p>Teeth that fail to erupt naturally are referred to as being impacted. The most common impacted teeth are third molars, also known as wisdom teeth. Other teeth, such as permanent canines can also be impacted. A simple surgical uncovery procedure is indicated in order to gain access to the impacted tooth so it can be orthodontically aligned. This is accomplished by removing and/or repositioning the gingival (gum) tissue covering the tooth (Figures 1a-b). At times, an orthodontic bracket is bonded to the impacted tooth so it can be pulled into proper position over time.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/impacted-tooth-uncovery/oralplastic_clip_image002.jpg"
        alt="Figure 1a: Impacted premolar covered by gum tissue">
        <figcaption>Figure 1a: Impacted premolar covered by gum tissue</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/impacted-tooth-uncovery/oralplastic_clip_image004.jpg"
        alt="Figure 1b: Impacted premolar uncovered"/>
        <figcaption>Figure 1b: Impacted premolar uncovered</figcaption>
      </figure>
    </div>
  </div>
</section>
