<section class="page-content fadeIn">
  <h1>
    <span>Ridge Augmentation</span>
  </h1>
  <p>Deformities in the upper or lower jaw can leave patients with inadequate bone to support dental implants. These deformities may have been caused by natural bone resorption following tooth extraction, periodontal disease, infection, wearing dentures, or trauma. As a result, the jaw may become too narrow. These deficiencies in the jaws prevent proper placement of dental implants and cause unattractive indentation around the implant restorations that may be difficult to clean and maintain. To correct the problem, the areas in the jaw that are deficient are reconstructed by placing bone graft material with growth factors under the gum tissue around the existing bone. After several months, the bone graft material incorporates into patient’s own bone which increases the dimensions of the jaw (Figures 1a-b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/ridge-augmentation/ridge_clip_image002.jpg"
        alt="Figure 1a: Narrow jaw that is inadequate to support a dental implant">
        <figcaption>Figure 1a: Narrow jaw that is inadequate to support a dental implant</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/ridge-augmentation/ridge_clip_image004.jpg"
        alt="Figure 1b: Jaw augmented with a bone graft has adequate width to support an implant"/>
        <figcaption>Figure 1b: Jaw augmented with a bone graft has adequate width to support an implant</figcaption>
      </figure>
    </div>
  </div>
</section>
