<section class="page-content fadeIn">
  <h1>
    <span>Oral Plastic Surgery</span>
  </h1>
  <p>Several oral plastic surgery techniques are available to improve the appearance of the gums. These procedures are intended to recontour the gum line to provide better symmetry as well as to re-establish the ideal length to width ratio. With a gingival “gum” graft, thin and receeding gums can be repositioned to cover exposed roots (Figures 1a-b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/oral-plastic-surgery/OralPlasticSurgeryFigure1a.jpg"
        alt="Figure 1a: Before grafting">
        <figcaption>Figure 1a: Before grafting</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/oral-plastic-surgery/OralPlasticSurgeryFigure1b.jpg"
        alt="Figure 1b: After grafting"/>
        <figcaption>Figure 1b: After grafting</figcaption>
      </figure>
    </div>
  </div>
  <p>In some individuals an excessive amount of gum tissue can make a person’s smile appear too “gummy” with short teeth. Recontouring and thinning of this excess gum tissue can provide a much more esthetic appearance (Figures 2a-b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/oral-plastic-surgery/OralPlasticSurgeryFigure2a.jpg"
        alt="Figure 2a: Before gingival recontouring">
        <figcaption>Figure 2a: Before gingival recontouring</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/oral-plastic-surgery/OralPlasticSurgeryFigure2b.jpg"
        alt="Figure 2b: After gingival recontouring"/>
        <figcaption>Figure 2b: After gingival recontouring</figcaption>
      </figure>
    </div>
  </div>
</section>
