import { Component } from '@angular/core';

@Component({
  selector: 'app-impacted-tooth-uncovery',
  templateUrl: './impacted-tooth-uncovery.component.html',
  styleUrls: ['./impacted-tooth-uncovery.component.scss']
})
export class ImpactedToothUncoveryComponent {

}
