
<section class="page-content">
  <h1>
    <span>Your First Appointment</span>
  </h1>
  <p>During your first visit our doctors will complete a thorough examination and diagnose the nature of your problem. Treatment options are then discussed in detail as well as benefits of the recommended treatment. For your convenience our staff provides our patients with financial information including estimated insurance benefits. Following the initial consultation, our doctors will communicate with your referring dentist to ensure that your treatment is properly coordinated and individualized based on your needs.</p>
  <h2>Office Hours</h2>
  <div>
    <table>
      <tr>
        <td>{{mobileQuery480.matches ? 'MON' :'Monday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'TUE' :'Tuesday'}}</td>
        <td>8:30 - 5:00</td>
        <td>(Lunch 12:00 - 2:00)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'WED' :'Wednesday'}}</td>
        <td>8:30 - 12:00</td>
        <td>(No doctors, staff only)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'THU' :'Thursday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'FRI' :'Friday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
    </table>
  </div>
  <h2>Financial Information</h2>
  <p>Our doctors and staff understand that accurate financial information is important for our patients. To expedite the treatment process, our staff will contact your insurance company (if applicable) for an estimate of your benefits. We will make every effort to make sure that this information is as accurate as possible. When necessary or requested by our patients we can obtain a written statement pre-authorizing the recommended treatment. In addition, as a courtesy to our patients, we offer several different payment plan options. Each option will be individualized based on your treatment needs and will be discussed with you during the initial consultation appointment. We highly encourage our patients to contact our office staff regarding any financial questions, concerns, or suggestions.</p>
</section>
