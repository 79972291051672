<section class="page-content fadeIn">
  <h1>
    <span>Sinus Augmentation</span>
  </h1>
  <p>Sinuses are empty spaces through which air passes during breathing. Sinuses are located in the back part of the upper jaw and in some cases may encroach on the bone needed to support the implant. Sinus augmentation, also know as the Sinus Lift, can help correct this problem by raising the sinus floor and adding bone to elevate the sinus away from the location of the implant. This can be completed in most cases at the same time with the implant placement. With this procedure (Figures 1a-b), a bone graft is carefully placed in the floor of the sinus which safely contains the implant.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/sinus-augmentation/sinus_clip_image002.jpg"
        alt="Figure 1a: Sinus located too close to the future position of the implant">
        <figcaption>Figure 1a: Sinus located too close to the future position of the implant</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/sinus-augmentation/sinus_clip_image004.jpg"
        alt="Figure 1b: Sinus augmentation provides bone containment around the dental implant"/>
        <figcaption>Figure 1b: Sinus augmentation provides bone containment around the dental implant</figcaption>
      </figure>
    </div>
  </div>
  <p>In some cases, sinus augmentation is completed prior to implant placement (Figures 2a-b). This is indicated in areas where the bone within the jaw is inadequate to predictably support the implant. Sinus augmentation is a safe procedure and does not have any negative effects on the function of the sinuses.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/sinus-augmentation/sinus_clip_image006.jpg"
        alt="Figure 2a: Position of the sinus prevents placement of a dental implant">
        <figcaption>Figure 2a: Position of the sinus prevents placement of a dental implant</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/sinus-augmentation/sinus_clip_image008.jpg"
        alt="Figure 2b: Sinus augmented prior to implant placement"/>
        <figcaption>Figure 2b: Sinus augmented prior to implant placement</figcaption>
      </figure>
    </div>
  </div>
</section>
