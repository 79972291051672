<section class="page-content fadeIn">
  <h1>
    <span>Atraumatic Tooth Extractions</span>
  </h1>
  <p>Atraumatic tooth extraction techniques are intended to minimize injury to the surrounding bone and gum tissue. This provides faster and more complete healing. Specially designed instruments are used to slowly separate the tooth from surrounding tissues. The tooth can then be carefully removed with minimal disruption.</p>
  <h2>Socket Preservation</h2>
  <p>After the tooth is extracted, the surrounding bone and gum tissue shrinks during the healing process. It has been shown that the edentulous (missing tooth) space can resorb by 50 percent or more. This can affect the adjacent teeth, as well as make tooth replacement options such as a fixed bridge or an implant more difficult. To preserve the edentulous space, a bone graft is placed directly into the extraction site immediately after the extraction (Figures 1a-d).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/atraumatic-tooth-extractions/uncovery_clip_image002.jpg"
        alt="Figure 1a: Tooth requiring an extraction">
        <figcaption>Figure 1a: Tooth requiring an extraction</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/atraumatic-tooth-extractions/uncovery_clip_image004.jpg"
        alt="Figure 1b: Extraction site after tooth is removed"/>
        <figcaption>Figure 1b: Extraction site after tooth is removed</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/atraumatic-tooth-extractions/uncovery_clip_image006.jpg"
        alt="Figure 1c: Bone graft placed in the extraction site">
        <figcaption>Figure 1c: Bone graft placed in the extraction site</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/atraumatic-tooth-extractions/uncovery_clip_image008.jpg"
        alt="Figure 1d: Extraction site is secured with sutures"/>
        <figcaption>Figure 1d: Extraction site is secured with sutures</figcaption>
      </figure>
    </div>
  </div>
  <p>This procedure, known as socket preservation, prevents collapse of bone and gum tissue during the healing process (Figures 2a-b). This provides a stable base for a dental implant and improves the long term prognosis of the adjacent teeth.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/atraumatic-tooth-extractions/uncovery_clip_image010.jpg"
        alt="Figure 2a: Space with socket preservation">
        <figcaption>Figure 2a: Space with socket preservation</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/atraumatic-tooth-extractions/uncovery_clip_image012.jpg"
        alt="Figure 2b: Space without socket preservation"/>
        <figcaption>Figure 2b: Space without socket preservation</figcaption>
      </figure>
    </div>
  </div>
</section>
