import { Component } from '@angular/core';

@Component({
  selector: 'app-implant-supported-dentures',
  templateUrl: './implant-supported-dentures.component.html',
  styleUrls: ['./implant-supported-dentures.component.scss']
})
export class ImplantSupportedDenturesComponent {

}
