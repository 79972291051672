import { Component } from '@angular/core';

@Component({
  selector: 'app-oral-plastic-surgey',
  templateUrl: './oral-plastic-surgey.component.html',
  styleUrls: ['./oral-plastic-surgey.component.scss']
})
export class OralPlasticSurgeyComponent {

}
