import { Component } from '@angular/core';

@Component({
  selector: 'app-dental-implants',
  templateUrl: './dental-implants.component.html',
  styleUrls: ['./dental-implants.component.scss']
})
export class DentalImplantsComponent {

}
