<section class="page-content fadeIn">
  <h1>
    <span>Gingival (Gum) Grafting</span>
  </h1>
  <p>In health, teeth are surrounded by keratinized gum tissue called gingiva. Gingiva appears pink in color and functions to protect the tooth and underlying bone (Figure 1). Compared to other tissues in the mouth such as cheek tissue, which appear red in color, healthy keratinized gingiva is thicker and denser. If gingiva is too thin or absent, gingival recession can occur. In addition, the tooth will be more prone to bacterial infection, bone loss, and worsening appearance.</p>
  <div class="figureGallery lightGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image002.jpg"
        alt="Figure 1: Healthy gum tissue">
        <figcaption>Figure 1: Healthy gum tissue</figcaption>
      </figure>
    </div>
  </div>
  <p>Gingival recession can be caused by and associated with a number of factors such as lack of keratinized gum tissue, thin gums, tooth position, or trauma (Figure 2a-d). Treatment of gingival recession usually involves a gingival (gum) graft procedure using processed collagen tissue or a small piece of tissue from the roof of the mouth.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image004.jpg"
        alt="Figure 2a: Gingival recession due to lack of keratinized gum tissue">
        <figcaption>Figure 2a: Gingival recession due to lack of keratinized gum tissue</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image006.jpg"
        alt="Figure 2b: Gingival recession due to thin gum tissue (Examination probe is visible through the gingiva)"/>
        <figcaption>Figure 2b: Gingival recession due to thin gum tissue (Examination probe is visible through the gingiva)</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image008.jpg"
        alt="Figure 2c: Gingival recession due to tooth position and prominent (bulging) roots">
        <figcaption>Figure 2c: Gingival recession due to tooth position and prominent (bulging) roots</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image010.jpg"
        alt="Figure 2d: Gingival recession due to trauma from tooth brush abrasion"/>
        <figcaption>Figure 2d: Gingival recession due to trauma from tooth brush abrasion</figcaption>
      </figure>
    </div>
  </div>
  <p>New techniques in gingival (gum) grafting provide less trauma and faster recovery for the patient. Drs. Shapiro and Baker complete over one thousand grafts each year. They utilize the latest techniques and materials in grafting to provide better results while keeping the patients comfortable. The goal of a gum graft procedure is to establish an adequate area of healthy, firm gum tissue around the tooth and reduce the risk of further recession. In addition, gingival (gum) grafts can cover the exposed roots and improve appearance (Figures 3a-b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image012.jpg"
        alt="Figure 3a: Untreated gingival recession">
        <figcaption>Figure 3a: Untreated gingival recession</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image014.jpg"
        alt="Figure 3b: Recession treated with a gum graft"/>
        <figcaption>Figure 3b: Recession treated with a gum graft</figcaption>
      </figure>
    </div>
  </div>
  <p>Gingival (gum) grafts can be completed by transferring a small piece of tissue from the roof of the patient’s mouth to the area of thin and receding gum tissue. Alternatively, donor tissue in the form of collagen, also known as Alloderm, can be used instead of patient’s own tissue (Figures 4a-b). Using Alloderm makes it possible to graft larger areas of recession involving multiple teeth while minimizing trauma.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/gingival-grafting/4a.jpg"
        alt="Figure 4a: Untreated gingival recession">
        <figcaption>Figure 4a: Untreated gingival recession</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/gingival-grafting/4b.jpg"
        alt="Figure 4b: Recession treated with Alloderm"/>
        <figcaption>Figure 4b: Recession treated with Alloderm</figcaption>
      </figure>
    </div>
  </div>
  <p>When used properly, results with an Alloderm graft are indistinguishable from grafts using patient’s own tissue. If indicated, Alloderm can be combined with palatal tissue to provide optimal results (Figures 5a-b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image019.jpg"
        alt="Figure 5a: Untreated gingival recession">
        <figcaption>Figure 5a: Untreated gingival recession</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/gingival-grafting/periodisease_clip_image021.jpg"
        alt="Figure 5b: Recession treated with Alloderm and palatal tissue grafts"/>
        <figcaption>Figure 5b: Recession treated with Alloderm and palatal tissue grafts</figcaption>
      </figure>
    </div>
  </div>
</section>
