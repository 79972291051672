<section class="page-content fadeIn">
  <h1>
    <span>3D Technology & Guided Surgery</span>
  </h1>
  <p>Three dimensional imaging using CT scan technology can provide a more complete and accurate view of the intra oral anatomy. For implant placement procedures, this information can be utilized for fully guided surgery with increased predictability and safety.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/three-d-technology/3d1.png"
        alt="Figure 1a">
        <figcaption>Figure 1a</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/three-d-technology/3d2.png"
        alt="Figure 1b"/>
        <figcaption>Figure 1b</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/three-d-technology/3d3.png"
        alt="Figure 1c"/>
        <figcaption>Figure 1c</figcaption>
      </figure>
    </div>
  </div>
</section>
