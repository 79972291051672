<div #mainContainer class="main-container">
  <mat-toolbar #mainToolbar class="main-toolbar">
      <img
          src="../assets/imgs/banner/gcp_logo.png"
          class="left-logo"
          height="auto"
          routerLink="/"/>
      <h1 class="column main-title">
          <span>SHAPIRO, BAKER AND WEBER</span>
          <span>Periodontics and Dental Implants</span>
      </h1>
      <h1 class="column mobile-title">
          <span>SHAPIRO, BAKER & WEBER</span>
          <span>Periodontics & Dental Implants</span>
      </h1>
      <span class="spacer"></span>
      <button mat-icon-button (click)="snav.toggle()" class="sideNavBtn">
          <mat-icon aria-hidden="false" aria-label="menu">menu</mat-icon>
      </button>
      <nav class="mainMenu">
        <button mat-button (click)="goTo('')">HOME</button>
        <button mat-button [matMenuTriggerFor]="doctorsMenu">DOCTORS</button>
        <mat-menu #doctorsMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="goTo('dr-vlad-shapiro')">Dr. Vlad Shapiro</button>
          <button mat-menu-item (click)="goTo('dr-andrew-baker')">Dr. Andrew Baker</button>
          <button mat-menu-item (click)="goTo('dr-margaret-weber')">Dr. Margaret Weber</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="patientsMenu">PATIENTS</button>
        <mat-menu #patientsMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="goTo('patient-info')">First Appointment</button>
          <button mat-menu-item (click)="goTo('forms')">Forms</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="servicesMenu">SERVICES</button>
        <mat-menu #servicesMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="goTo('treatment-of-periodontal-disease')">Treatment of Periodontal Disease</button>
          <button mat-menu-item (click)="goTo('crown-lengthening')">Crown Lengthening</button>
          <button mat-menu-item (click)="goTo('gingival-grafting')">Gingival (Gum) Grafting</button>
          <button mat-menu-item (click)="goTo('oral-plastic-surgery')">Oral Plastic Surgery</button>
          <button mat-menu-item (click)="goTo('esthetic-smile-design')">Esthetic Smile Design</button>
          <button mat-menu-item (click)="goTo('dental-implants')">Dentals Implants</button>
          <button mat-menu-item (click)="goTo('implant-supported-dentures')">Implant Supported Dentures</button>
          <button mat-menu-item (click)="goTo('atraumatic-tooth-extractions')">Atraumatic Tooth Extractions</button>
          <button mat-menu-item (click)="goTo('ridge-augmentation')">Ridge Augmentation</button>
          <button mat-menu-item (click)="goTo('sinus-augmentation')">Sinus Augmentation</button>
          <button mat-menu-item (click)="goTo('impacted-tooth-uncovery')">Impacted Tooth Uncovery</button>
          <button mat-menu-item (click)="goTo('frenectomy')">Frenectomy</button>
          <button mat-menu-item (click)="goTo('oral-pathology')">Oral Pathology</button>
          <button mat-menu-item (click)="goTo('sedation')">Sedation</button>
          <button mat-menu-item (click)="goTo('three-d-technology')">3D Technology and Guided Surgery</button>
        </mat-menu>
        <button mat-button (click)="goTo('/links')">LINKS</button>
        <button mat-button (click)="goTo('referring-doctors')">REFERRALS</button>
        <button mat-button (click)="goTo('contact')">CONTACT</button>
      </nav>
  </mat-toolbar>
  <mat-sidenav-container class="main-sidenav-container" autoSize>
    <mat-sidenav #snav
      [mode]="mobileQuery1280.matches ? 'over' : 'side'"
      [opened]="sidenavOpen"
      [fixedInViewport]="mobileQuery1280.matches">
      <nav class="sideNavMenu">
        <mat-nav-list dense>
          <mat-list-item>
            <a mat-list-item routerLink="/">Home</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item [matMenuTriggerFor]="servicesMenu">Services</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item [matMenuTriggerFor]="doctorsMenu">Doctors</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item routerLink="/patient-info">Patients</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item routerLink="/forms">Forms</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item routerLink="/links">Links</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item routerLink="/referring-doctors">Referrals</a>
          </mat-list-item>
          <mat-list-item>
            <a mat-list-item routerLink="/contact">Contact</a>
          </mat-list-item>
        </mat-nav-list>
        <mat-menu #doctorsMenu="matMenu" xPosition="after">
          <button mat-menu-item (click)="goTo('dr-vlad-shapiro')">Dr. Vlad Shapiro</button>
          <button mat-menu-item (click)="goTo('dr-andrew-baker')">Dr. Andrew Baker</button>
          <button mat-menu-item (click)="goTo('dr-margaret-weber')">Dr. Margaret Weber</button>
        </mat-menu>
        <mat-menu #servicesMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="goTo('treatment-of-periodontal-disease')">Treatment of Periodontal Disease</button>
          <button mat-menu-item (click)="goTo('crown-lengthening')">Crown Lengthening</button>
          <button mat-menu-item (click)="goTo('gingival-grafting')">Gingival (Gum) Grafting</button>
          <button mat-menu-item (click)="goTo('oral-plastic-surgery')">Oral Plastic Surgery</button>
          <button mat-menu-item (click)="goTo('esthetic-smile-design')">Esthetic Smile Design</button>
          <button mat-menu-item (click)="goTo('dental-implants')">Dentals Implants</button>
          <button mat-menu-item (click)="goTo('implant-supported-dentures')">Implant Supported Dentures</button>
          <button mat-menu-item (click)="goTo('atraumatic-tooth-extractions')">Atraumatic Tooth Extractions</button>
          <button mat-menu-item (click)="goTo('ridge-augmentation')">Ridge Augmentation</button>
          <button mat-menu-item (click)="goTo('sinus-augmentation')">Sinus Augmentation</button>
          <button mat-menu-item (click)="goTo('impacted-tooth-uncovery')">Impacted Tooth Uncovery</button>
          <button mat-menu-item (click)="goTo('frenectomy')">Frenectomy</button>
          <button mat-menu-item (click)="goTo('oral-pathology')">Oral Pathology</button>
          <button mat-menu-item (click)="goTo('sedation')">Sedation</button>
          <button mat-menu-item (click)="goTo('three-d-technology')">3D Technology and Guided Surgery</button>
        </mat-menu>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content class="main-sidenav-content" #mainSidenavContent>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
