import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, RouterModule } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-dr-vlad-shapiro',
  templateUrl: './dr-vlad-shapiro.component.html',
  styleUrls: ['./dr-vlad-shapiro.component.scss']
})
export class DrVladShapiroComponent {

  mobileQuery960: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private cd: ChangeDetectorRef,
    media: MediaMatcher) {
      this.mobileQuery960 = media.matchMedia('(max-width:960px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery960.addListener(this._mobileQueryListener);
  }
}
