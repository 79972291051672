<section class="page-content fadeIn">
  <h1>
    <span>Crown Lengthening</span>
  </h1>
  <p>In order for a filling or a crown restoration to be placed properly, adequate tooth crown height above the gum line is required. In addition, adequate distance is needed between the restoration and underlying bone. Crown lengthening is a surgical procedure that recontours the gum tissue and the underlying bone surrounding one or more teeth so that a sufficient amount of healthy tooth is exposed. Crown lengthening is often used as part of a treatment plan for a tooth that is to be fitted with a crown. This procedure provides the necessary space between the supporting bone and crown, preventing the new crown from impinging upon and damaging the gum tissues and underlying bone. If a tooth is badly worn, decayed, or fractured below the gum line, crown lengthening surgical treatment provides the necessary access to more of the tooth so it can be properly restored (Figures 1a and b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/crown-lengthening/frenectomy_clip_image002.jpg"
        alt="Figure 1a: Before crown lengthening">
        <figcaption>Figure 1a: Before crown lengthening</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/crown-lengthening/frenectomy_clip_image004.jpg"
        alt="Figure 1b: After crown lengthening"/>
        <figcaption>Figure 1b: After crown lengthening</figcaption>
      </figure>
    </div>
  </div>
  <p>Crown lengthening can also be done to improve appearance of teeth. The gum line can be recontoured to provide more esthetic proportions between the teeth and gums (Figures 2a and b).</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/crown-lengthening/frenectomy_clip_image006.jpg"
        alt="Figure 2a: Before crown lengthening">
        <figcaption>Figure 2a: Before crown lengthening</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/crown-lengthening/frenectomy_clip_image008.jpg"
        alt="Figure 2b: After crown lengthening"/>
        <figcaption>Figure 2b: After crown lengthening</figcaption>
      </figure>
    </div>
  </div>
</section>
