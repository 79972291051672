<section class="page-content fadeIn">
  <h1>
    <span>Dental Implants</span>
  </h1>
  <p>A dental implant is an artificial tooth root that is used to support a single crown or a fixed bridge. The implant is placed into the jaw with a minor surgical procedure by the periodontist. Several months are required for the bone to attach to the implant surface. At that time, the restoring dentist can place a post, also known as the abutment, into the implant. Once the abutment is in place the restorative dentist will place a crown onto it to create new fully functioning “tooth” for the patient (Figure 1).</p>
  <div class="figureGallery lightGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/dental-implants/crownlengthening_clip_image002.jpg"
        alt="Figure 1: Implant Supported Restoration">
        <figcaption>Figure 1: Implant Supported Restoration</figcaption>
      </figure>
    </div>
  </div>
  <p>Implants can be predictably used to replace a single missing tooth (Figures 2a-b), several missing teeth, or restore a completely edentulous jaw (Figure 3a-b) Implant restorations have several advantages over fixed bridges, such as preventing bone resorption and conserving tooth structure of adjacent teeth. Implant restorations provide excellent chewing function and natural appearance. Dr. Shapiro is Board Certified by the American Board of Periodontology in implant dentistry and has taken part in clinical research focused on implant healing. He offers the most current and predictable techniques available in implant related therapy.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/dental-implants/DentalImplantsFigure2a.jpg"
        alt="Figure 2a: Missing Front Tooth">
        <figcaption>Figure 2a: Missing Front Tooth</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/dental-implants/DentalImplantsFigure2b.jpg"
        alt="Figure 2b: Tooth Replaced with an Implant"/>
        <figcaption>Figure 2b: Tooth Replaced with an Implant</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/dental-implants/crownlengthening_clip_image008.jpg"
        alt="Figure 3a: Hopeless Upper and Lower Teeth">
        <figcaption>Figure 3a: Hopeless Upper and Lower Teeth</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/dental-implants/crownlengthening_clip_image010.jpg"
        alt="Figure 3b: Upper and Lower Jaws Restored with Implants"/>
        <figcaption>Figure 3b: Upper and Lower Jaws Restored with Implants</figcaption>
      </figure>
    </div>
  </div>
</section>
