import { Component } from '@angular/core';

@Component({
  selector: 'app-three-d-technology',
  templateUrl: './three-d-technology.component.html',
  styleUrls: ['./three-d-technology.component.scss']
})
export class ThreeDTechnologyComponent {

}
