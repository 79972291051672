<section class="page-content">
  <h1><span>Dr. Vlad Shapiro D.D.S.</span></h1>
  <div class="align-bottom" [ngClass]="mobileQuery960.matches ? 'column' : 'row'">
    <div class="col1">
      <img src="../../assets/imgs/dr-vlad-shapiro/vlad.png" alt="Doctor Vlad Shapiro" class="vlad-pic"/>
    </div>
    <div class="col2">
      <p>Dr. Shapiro grew up in Cincinnati, where he graduated from Sycamore High School and attended the University of Cincinnati. He completed his dental training in 2005, followed by periodontal residency, all at the Ohio State University. During residency, Dr. Shapiro conducted clinical research on dental implants. Through years of research, he acquired invaluable knowledge which he applies in his treatment today. Dr. Shapiro also practiced general dentistry in Columbus, Ohio with heavy emphasis on family and pediatric dentistry. After moving to Cincinnati and joining private practice specialized in periodontics, Dr. Shapiro continued to further advance his education by becoming Board Certified by the American Board of Periodontology, as well as being an active member of the American Dental Association, the American Academy of Periodontology, the Ohio and Cincinnati Dental Associations, and Midwest Society of Periodontics. In addition to private practice, Dr. Shapiro became a part-time staff periodontist at the Cincinnati VA Hospital in 2011. He takes pride and enjoyment in providing periodontal care to our nation’s courageous veterans.</p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-vlad-shapiro/ShapiroPhoto2-e1439423665300.jpg" alt="Doctor Vlad Shapiro and Family"/>
      </p>
      <p>
        Dr. Shapiro emphasizes strong continuing education for himself and his colleagues. He has become a certified continuing education provider in the state of Ohio. Throughout the year he provides courses to students, practicing doctors, and dental hygienists on current topics in periodontics, gingival grafting, periodontal plastic surgery, and dental implants. In his practice, he emphasizes communication with his patients and with referring doctors to ensure the best treatment options and care.
      </p>
      <p class="row text-center">
        <img src="../../assets/imgs/dr-vlad-shapiro/ShapiroPhoto3-e1439423647668.jpg" alt="Doctor Vlad Shapiro and Family"/>
      </p>
      <p>
        Dr. Shapiro is married to his wife Andrea, and is the proud father of two boys. In his spare time he loves being with his family, as well as keeping active through fitness and athletics. Dr. Shapiro is a hockey fanatic and continues to play in the local amateur leagues. He stays active in the community as a coach for boys’ soccer and teaching kids the importance of sports and fitness.
      </p>
    </div>
  </div>
</section>
