<section class="page-content fadeIn">
  <h1>
    <span>Oral Pathology – Biopsy And Diagnosis</span>
  </h1>
  <p>A variety of abnormal lesions may arise in the oral cavity. These lesions may require removal and biopsy for a proper diagnosis (Figures 1a-c). Depending on the size and location of the lesion this is accomplished by carefully removing the lesion after application of local anesthetic.</p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/oral-pathology/pathology_clip_image002.jpg"
        alt="Figure 1a: Abnormal lesion originating from the gingiva">
        <figcaption>Figure 1a: Abnormal lesion originating from the gingiva</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/oral-pathology/pathology_clip_image004.jpg"
        alt="Figure 1b: Abnormal lesion on the inside of the lip"/>
        <figcaption>Figure 1b: Abnormal lesion on the inside of the lip</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/oral-pathology/pathology_clip_image006.jpg"
        alt="Figure 1c: Abnormal lesion located on the base of the tongue"/>
        <figcaption>Figure 1c: Abnormal lesion located on the base of the tongue</figcaption>
      </figure>
    </div>
  </div>
</section>
