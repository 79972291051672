<section class="page-content fadeIn">
  <h1><span>Links</span></h1>
  <h2>Professional Organizations</h2>
  <ul>
    <li>
      <a href="http://www.perio.org/" target="_blank">American Academy of Periodontology</a>
    </li>
    <li>
      <a href="http://www.ada.org/" target="_blank">American Dental Association</a>
    </li>
    <li >
      <a href="http://www.oda.org/" target="_blank">Ohio Dental Association</a>
    </li>
    <li>
      <a href="http://www.cincinnatidental.org/" target="_blank">Cincinnati Dental Society</a>
    </li>
  </ul>
  <h2>Implant and Regenerative Products Manufacturers</h2>
  <ul>
    <li>
      <a href="http://www.straumann.us/" target="_blank">Straumann Dental Implants and Regenerative Products</a>
    </li>
    <li>
      <a href="http://www.astratech.us/" target="_blank">Astra Dental Implants</a>
    </li>
  </ul>
</section>
