import { Component } from '@angular/core';

@Component({
  selector: 'app-oral-pathology',
  templateUrl: './oral-pathology.component.html',
  styleUrls: ['./oral-pathology.component.scss']
})
export class OralPathologyComponent {

}
