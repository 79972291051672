import { Component } from '@angular/core';

@Component({
  selector: 'app-laser-surgery',
  templateUrl: './laser-surgery.component.html',
  styleUrls: ['./laser-surgery.component.scss']
})
export class LaserSurgeryComponent {

}
