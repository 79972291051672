import { Component } from '@angular/core';

@Component({
  selector: 'app-referring-doctors',
  templateUrl: './referring-doctors.component.html',
  styleUrls: ['./referring-doctors.component.scss']
})
export class ReferringDoctorsComponent {

}
