import { Component } from '@angular/core';

@Component({
  selector: 'app-periodontal-surgery',
  templateUrl: './periodontal-surgery.component.html',
  styleUrls: ['./periodontal-surgery.component.scss']
})
export class PeriodontalSurgeryComponent {

}
