import { Component } from '@angular/core';

@Component({
  selector: 'app-sinus-augmentation',
  templateUrl: './sinus-augmentation.component.html',
  styleUrls: ['./sinus-augmentation.component.scss']
})
export class SinusAugmentationComponent {

}
