import { Component } from '@angular/core';

@Component({
  selector: 'app-sedation',
  templateUrl: './sedation.component.html',
  styleUrls: ['./sedation.component.scss']
})
export class SedationComponent {

}
