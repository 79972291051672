import { Component } from '@angular/core';

@Component({
  selector: 'app-bone-grafting-and-regeneration',
  templateUrl: './bone-grafting-and-regeneration.component.html',
  styleUrls: ['./bone-grafting-and-regeneration.component.scss']
})
export class BoneGraftingAndRegenerationComponent {

}
