import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('snav') public snav!: MatSidenav;
  @ViewChild('routerOutlet') public routerOutlet!: Element;
  @ViewChild('mainSidenavContent') public mainSidenavContent!: Element;
  mobileQuery720: MediaQueryList;
  mobileQuery960: MediaQueryList;
  mobileQuery1024: MediaQueryList;
  mobileQuery1280: MediaQueryList;
  private _mobileQueryListener: () => void;
  public sidenavOpen: boolean = false;
  public fixedHeader: boolean = false;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    media: MediaMatcher,
  ) {
    this.mobileQuery720 = media.matchMedia('(max-width: 720px)');
    this.mobileQuery960 = media.matchMedia('(max-width: 960px)');
    this.mobileQuery1024 = media.matchMedia('(max-width: 1024px)');
    this.mobileQuery1280 = media.matchMedia('(max-width: 1280px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery720.addListener(this._mobileQueryListener);
    this.mobileQuery960.addListener(this._mobileQueryListener);
    this.mobileQuery1024.addListener(this._mobileQueryListener);
    this.mobileQuery1280.addListener(this._mobileQueryListener);
  }
  title = 'shapiro-and-baker';

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (this.mainSidenavContent != null) {
        this.mainSidenavContent.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }

      if (evt instanceof NavigationStart) {
        this.sidenavOpen = false;
      }

      if (!(evt instanceof NavigationEnd)) {
        if (this.mobileQuery1280.matches) {
          this.snav.close();
        }
      }
      return;
    });
  }

  goTo(r:string) {
    this.router.navigate([r]);
  }

  @HostListener('window.resize', ['$event'])
  onResize(e: Event) {
    if(!this.mobileQuery1280.matches) {
      this.snav.close();
      this.sidenavOpen = false;
    }
  }
}
