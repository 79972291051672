import { Component } from '@angular/core';

@Component({
  selector: 'app-atraumatic-tooth-extractions',
  templateUrl: './atraumatic-tooth-extractions.component.html',
  styleUrls: ['./atraumatic-tooth-extractions.component.scss']
})
export class AtraumaticToothExtractionsComponent {

}
