import { Component } from '@angular/core';

@Component({
  selector: 'app-treatment-of-periodontal-disease',
  templateUrl: './treatment-of-periodontal-disease.component.html',
  styleUrls: ['./treatment-of-periodontal-disease.component.scss']
})
export class TreatmentOfPeriodontalDiseaseComponent {

}
