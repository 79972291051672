<section class="page-content">
  <h1>
    <span>Forms</span>
  </h1>
  <p>Please print all registration forms and instructions. Please remember to bring the completed forms to your first appointment.</p>
  <h2>Registration Forms</h2>
  <ul>
    <li>
      <a href="../../assets/pdfs/forms/Registration-Instructions-2024.pdf" target="_blank">Registration Instructions</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Notice-of-Privacy-Practices.pdf" target="_blank">Notice of Privacy Practices</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Acknowlegement-of-Privacy-Practices-2024.pdf" target="_blank">Acknowlegement of Privacy Practices</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Financial-Agreement-2024.pdf" target="_blank">Financial Agreement</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Patient-History-Form.pdf" target="_blank">Patient History Form</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Release-of-Information-Consent-2024.pdf" target="_blank">Release of Information Consent</a>
    </li>
  </ul>
  <h2>Pre-Operative Instructions</h2>
  <ul>
    <li>
      <a href="../../assets/pdfs/forms/Pre-Operative-Instructions.pdf" target="_blank">Pre-Operative Instructions</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Pre-IV-Sedation-Instructions.pdf" target="_blank">Pre IV Sedation Instructions</a>
    </li>
  </ul>
  <h2>Post-Operative Instructions</h2>
  <ul>
    <li>
      <a href="../../assets/pdfs/forms/Post-Operative-Instructions-For-Gum-Graft-Procedure-2024.pdf" target="_blank">Post-Operative Instructions For Gum Graft Procedure</a>
    </li>
    <li>
      <a href="../../assets/pdfs/forms/Post-Operative-Instructions-For-Gum-Graft-Procedure-2024.pdf" target="_blank">Post-Operative Instructions For Surgical Procedure</a>
    </li>
  </ul>
</section>
