import { Component } from '@angular/core';

@Component({
  selector: 'app-crown-lengthening',
  templateUrl: './crown-lengthening.component.html',
  styleUrls: ['./crown-lengthening.component.scss']
})
export class CrownLengtheningComponent {

}
